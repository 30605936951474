import { Component, OnInit } from '@angular/core';

import { AuthService } from 'apps/arsultima/src/app/core/authentication/services/auth/auth.service';
import { DataService } from './services/data.service';
import { LangKeys } from './config/lang.config';
import { Observable } from 'rxjs';
import { Preferences } from './modules/settings/models/settings.model';
import { SettingsService } from './services/settings.service';
import { TranslationService } from './services/translation.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserConnexion } from './models/auth.model';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'arsultima-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Arsultima';

  canEdit = false;

  client: Preferences;

  lang$: Observable<LangKeys>;

  userConnexion$: Observable<UserConnexion>;

  constructor(
    private readonly authService: AuthService,
    private readonly settingsService: SettingsService,
    private readonly dataService: DataService,
    private readonly translationService: TranslationService,
  ) {
    this.getUserConnection();
    this.authService.getInitialConnection();
  }

  ngOnInit() {
    this.handleConnexion();
    this.handleLanguage();
  }

  handleConnexion() {
    this.dataService.currentUserConnection$.subscribe(userConnection => {
      if (userConnection.hasOwnProperty('canEdit')) {
        this.canEdit = userConnection.canEdit;
      }

      if (userConnection.db) {
        this.loadClient();
      }
    });
  }

  handleLanguage() {
    this.lang$ = this.translationService.getSubscriptionLangChanges();
  }

  signout() {
    this.authService.signout();
  }

  loadClient() {
    this.settingsService.getPreferences().subscribe(client => {
      if (client && client.fields) {
        this.client = client;
        this.dataService.setClient(client);
        this.translationService.setLangFromClient(client);
      }
    });
  }

  private getUserConnection() {
    this.userConnexion$ = this.dataService.currentUserConnection$;
  }
}
