import { DatabaseContentType } from '../modules/database/models/database.model';
export class URL_ROUTER {
  static homeConnected(db: string): string {
    return this.homeCollection(db);
  }

  static homeDisconnectedUser(db: string): string {
    return this.homeCollection(db);
  }

  static homeDisconnected(): string {
    return this.homeArsultima();
  }

  // AUTH PAGES
  static connect(): string {
    return 'connect';
  }

  static passwordReset(): string {
    return 'password/reset';
  }

  static emailValidate(): string {
    return 'email-validate';
  }

  static login(): string {
    return `${this.connect()}/login`;
  }

  static contact(): string {
    return 'contact';
  }

  static home(): string {
    return 'home';
  }

  // Page only existing for arsu
  // that describe the arsultima service
  static discover(): string {
    return `discover`;
  }

  static database(): string {
    return 'database';
  }

  // Prefix for public collections
  // Ex:
  // collection/cfag/exhibit
  // collection/lind/exhibit

  static publicCollectionPrefix(): string {
    return 'collection';
  }

  static publicCollections(collectionName: string): string {
    return `${this.publicCollectionPrefix()}/${collectionName}`;
  }

  // EXHIBIT PAGE

  static exhibitBase(): string {
    return `exhibit`;
  }

  static exhibitSegmentTable(): string {
    return `table`;
  }

  static exhibitSegmentWall(): string {
    return `wall`;
  }

  static exhibitTable(collectionName: string): string {
    return `${this.publicCollections(collectionName)}/${this.exhibitBase()}/${this.exhibitSegmentTable()}`;
  }

  static exhibitWall(collectionName: string): string {
    return `${this.publicCollections(collectionName)}/${this.exhibitBase()}`;
  }

  static exhibitHome(collectionName: string): string {
    return `${this.publicCollections(collectionName)}/${this.home()}`;
  }

  static contactCollection(collectionName: string): string {
    return `${this.publicCollections(collectionName)}/${this.contact()}`;
  }

  // ARSULTIMA PUBLIC

  // Common prefix for arsultima related pages
  static arsultimaPrefix(): string {
    return 'arsultima';
  }

  // To display information about arsultima service
  static homeArsultima(): string {
    return `${this.arsultimaPrefix()}/${this.home()}/index`;
  }

  static discoverArsultima(): string {
    return `${this.arsultimaPrefix()}/${this.home()}/${this.discover()}`;
  }

  // For visitor to contact arsultima admin
  static contactArsultima(): string {
    return `${this.arsultimaPrefix()}/${this.contact()}`;
  }

  static homeCollection(collectionName: string): string {
    return `${this.publicCollections(collectionName)}/${this.home()}`;
  }

  static databaseCreate(type: string): string {
    return `${this.database()}/${type}/edit/0`;
  }

  static databaseEdit(type: string, id: string): string {
    return `${this.database()}/${type}/edit/${id}`;
  }

  static databaseList(type: DatabaseContentType): string {
    return `${this.database()}/${type}`;
  }

  static databaseWorks(): string {
    return `${this.database()}/works`;
  }

  static addressEdit(id: string): string {
    return this.databaseEdit('addresses', id);
  }

  static stats(): string {
    return 'stats';
  }

  static viewer(): string {
    return 'viewer';
  }

  // SLIDE

  static slide(): string {
    return 'slide';
  }

  static slideAdd(): string {
    return `${this.slide()}/add`;
  }

  static slideEdit(id: string): string {
    return `${this.slide()}/${id}/edit`;
  }

  // BLOCK

  static block(): string {
    return 'block';
  }

  static blockAdd(): string {
    return `${this.block()}/add`;
  }

  static blockEdit(id: string): string {
    return `${this.block()}/${id}/edit`;
  }

  // Settings
  static settings(): string {
    return 'settings';
  }

  static settingsSlider(): string {
    return 'slider';
  }

  static settingsAdmin(): string {
    return 'admin';
  }

  static settingsLists(): string {
    return 'lists';
  }

  static settingsHomeSlider(): string {
    return 'settings/slider';
  }
}
