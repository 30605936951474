import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MATERIAL_FIELD_CONFIG, MATERIAL_SNACKBAR_CONFIG } from './config/material.config';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { HttpClientModule } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NgModule } from '@angular/core';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { ThemeModule } from './theme/theme.module';
import { TranslocoRootModule } from './transloco-root.module';
import { ValidatorErrorConfig } from './config/forms-validation.config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ThemeModule,
    AppRoutingModule,
    CoreModule,
    NgxUsefulSwiperModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    TranslocoRootModule,

    ErrorTailorModule.forRoot(ValidatorErrorConfig),
  ],
  providers: [
    // Set the default duration of snackbar to 5000ms
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: MATERIAL_SNACKBAR_CONFIG },

    // Set the default style of material field to 'fill' style
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: MATERIAL_FIELD_CONFIG },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
